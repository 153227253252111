import { graphql } from "gatsby"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  banner
} from "./BannerImage.module.scss"

export const fragment = graphql`
  fragment bannerImageFragment on WpPage_Flexlayouts_FlexibleLayouts_BannerImage {
    bannerImage {
      ...FullWidthImageQuery
    }
  }
`

const BannerImage = ({ bannerImage, index }) => {

  const image = getImage(bannerImage?.localFile)

  return (
    <div id="banner" className={banner}>
      <GatsbyImage
        image={image}
        alt={bannerImage?.altText}
        style={{ maxHeight: "416px" }}
        grayscale={true}
        loading={index===0 ? 'eager' : 'lazy'}
      />
    </div>
  )
}

export default BannerImage